import { JObject } from "../../common/data/models";
import { CategoryFields } from "../catalog/products/domain/dtos/category-fields";
import { City } from "../cities/domain/dtos";
import { BusinessTypeFile } from "../partners/data";

export type TawreedMenuItem<T = any> = {
    id?: number;
    value: T;
    label: string;
    type: string;
    langCode: string;
};
export type TawreedMenu<T = any> = Array<TawreedMenuItem<T>>;

export type TawreedInfo = {
    contactUsEmailSubject: string;
    contactUsEmail: string;
    contactUsMobileNo: string;
    contactUsWhatsAppNo: string;
    countryCode: string;
    contactUsWhatsAppMsg: string;
    contactUsEmailBody: string;
    siteName: string;
    currencyCode: string;
}

export type TawreedConstants = {
    roles: TawreedMenu;
    languages: TawreedMenu;
    sites: TawreedMenu;
    partnerTypes: TawreedMenu;
    partnerStatus: TawreedMenu;
    orderStatuses: TawreedMenu;
    paymentMethods: TawreedMenu;
    shipmentMethods: TawreedMenu;
    returnReasons: TawreedMenu;
    cancelReasons: TawreedMenu;
    cancelReasonsCustomer: TawreedMenu;
    documentTypes: TawreedMenu;
    productCategories: TawreedMenu;
    discountTypes: TawreedMenu;
    invoiceDiscountTypes: TawreedMenu;
    importHelperStatus: TawreedMenu;
    walletStatus: TawreedMenu;
    orderDocumentTypes: TawreedMenu,
    canShippingOptions: TawreedMenu,
    storeRawStatus: TawreedMenu;
    storeRawConfirmStatus: TawreedMenu;
    discountTypesBasics: TawreedMenu;
    businessTypesCustomer: TawreedMenu;
    businessTypesStore: TawreedMenu;
    businessTypesFilesStore: TawreedMenu<BusinessTypeFile[]>;
    businessTypesFilesCustomer: TawreedMenu<BusinessTypeFile[]>;
    nationalityList: TawreedMenu<any>;
    businessTypesTopUp: TawreedMenu;
    transactionStatuses: TawreedMenu;
    paymentransactionStatuses: TawreedMenu;
    paymentTransactionType: TawreedMenu;
    paymentOrderStatuses: TawreedMenu;
    paymentTransactionTypes: TawreedMenu;
    info: TawreedInfo;
    productCategoriesFields: CategoryFields[];
    paymentModes: TawreedMenu;
    financeAccountsTypes: TawreedMenu;
    cities: City[];
    classifications: TawreedMenu;
    bonusOperations: TawreedMenu;
    quantityType: TawreedMenu;
    quantitySource: TawreedMenu;
    notificationTypes: TawreedMenu;
    [key: string]: TawreedMenu | TawreedInfo | CategoryFields[] | City[];
}

export interface ConstantsState {
    loading: boolean;
    error?: string;
    constants?: TawreedConstants;
}

export function getInitialConstantsState(): ConstantsState {
    return { loading: false };
}

// export function constantsMenuToDropdownOptions(menu: TawreedMenu) {
//     return [];
// }


export const TawreedMenuItemMapper = {

    fromJson(obj: JObject): TawreedMenuItem {
        return {
            id: obj.id,
            label: obj.label,
            langCode: obj.langCode,
            type: obj.type,
            value: obj.value,
        }
    }
}

export enum IBonusType {
    DISCOUNT = "DISCOUNT",
    EXTRA_ITEMS = "EXTRA_ITEMS",

}
export enum IBonusOperations {
    EQUALS = "EQUALS",
    GREATER_THAN = "GREATER_THAN",
}
export enum IStockType {
    Rest= "reset",
    Inbound = "Inbound",
    Outbound = "Outbound",
}
export enum IStockSource {
    Order= "Order",
    Manual = "Manual",
    Update = "Update",
    External = "External",
    Import = "Import",
}
export enum IimportHelperStatus {
    COMPLETED = "COMPLETED",
    PENDING = "PENDING",
    FAILED = "FAILED",
    NOT_STARTED = "NOT_STARTED"
}
export enum IStoreProductRawStats {
    EXACT_MATCH = "EXACT",
    HIGH_SIMILARITY = "HIGH_SIMILARITY",
    MEDIUM_SIMILARITY = "MEDIUM_SIMILARITY",
    LOW_SIMILARITY = "LOW_SIMILARITY",
    NO_MATCH = "NONE",
    NOT_STARTED = "NOT_STARTED",
    INVALID = "INVALID"
}


