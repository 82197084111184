import React from "react"
import { Panel } from "primereact/panel"
import { useParams } from "react-router-dom"
import { GlobalContext } from "../../../../context"
import { ReportService, StatementReportItem } from "../../domain"
import { tawreedTranslationService } from "../../../../common/translations"
import { TawreedCalendar, TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitProps, TawreedInputNumber, TawreedInputText, TawreedMenuDropdown } from "../../../../common"
import { FinancePaymentMethodDropdown } from "../../../finance/presentaion/components/finance-payment-method.dropdown"


export const TransactionForm: React.FC = () => {

    const { id } = useParams<{ id: string | undefined }>();
    const { auth: { user } } = React.useContext(GlobalContext);
    const service: ReportService = React.useMemo(() => new ReportService(), []);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [mode, setMode] = React.useState<TawreedFormMode>('Create');
    const [data, setData] = React.useState<StatementReportItem>();

    React.useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {
                setMode('Edit');
                setLoading(true);
            }
            service.getDetails(Number.parseInt(id))
                .then(res => {
                    if (mounted) {
                        setLoading(false);
                        setData(res);
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })
        } else {
            if (mounted) {
                setMode('Create');
                setData(undefined);
            }
        }
        return () => {
            mounted = false;
        };
    }, [id, service]);


    const onSubmit: TawreedFormSubmitProps<StatementReportItem> = { showButton: false, resetOnSubmit: false };

    return (
        <TawreedForm title="lbl_topup_transactions"
            dataKey="txnTopUpId" data={data}
            submit={onSubmit}
            loading={loading} mode={mode}
            useBack=".." className="grid form-grid">
            <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                <div className="grid">
                    <TawreedFormField name="transactionId" className="field col-12 md:col-6" title="lbl_reports_statements_transactionid">
                        <TawreedInputText name="transactionId" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="txnDate" className="field col-12 md:col-6" title="lbl_reports_statements_txndate">
                        <TawreedCalendar name="txnDate" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="transactionTypeName" className="field col-12 md:col-6" title="lbl_reports_statements_type">
                        <TawreedInputText name="transactionTypeName" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="description" className="field col-12 md:col-6" title="lbl_reports_statements_description">
                        <TawreedInputText name="description" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="creditAmount" className="field col-12 md:col-6" title="lbl_reports_statements_creditamount">
                        <TawreedInputNumber mode="decimal" name="creditAmount" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="debitAmount" className="field col-12 md:col-6" title="lbl_reports_statements_debitamount">
                        <TawreedInputNumber mode="decimal" name="debitAmount" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>


                    <TawreedFormField name="balance" className="field col-12 md:col-6" title="lbl_reports_statements_balance">
                        <TawreedInputNumber mode="decimal" name="balance" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="orderId" className="field col-12 md:col-6" title="lbl_reports_statements_order_id">
                        <TawreedInputNumber name="orderId" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="total" className="field col-12 md:col-6" title="lbl_reports_statements_order_total">
                        <TawreedInputNumber mode="decimal" name="total" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="invoiceNo" className="field col-12 md:col-6" title="lbl_reports_statements_invoice_no">
                        <TawreedInputText name="invoiceNo" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    {/* <TawreedFormField name="paymentMethodId" className="field col-12 md:col-6" title="lbl_topup_txn_partner">
                        <TawreedInputText name="paymentMethodId" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField> */}
                     <TawreedFormField name="paymentMethodId" title="lbl_reports_statements_payment_method_id" className="field col-12 md:col-6">
                            <FinancePaymentMethodDropdown render="form" name="paymentMethodId" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="paymentReference" className="field col-12 md:col-6" title="lbl_reports_statements_payment_method_refrence">
                        <TawreedInputText name="paymentReference" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>
                </div>
            </Panel>
        </TawreedForm>
    )
}